





























import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ViewPunishmentProofModal extends Vue {
  isOpen = false
  id: Nullable<number> = null
  punishmentLog: Nullable<PunishmentLog> = null

  async mounted() {
    this.$root.$on('openViewPunishmentProofModal', punishmentLog => {
      this.isOpen = true
      this.punishmentLog = punishmentLog
    })
  }

  openImage(proof) {
    window.open(proof)
  }
}
